import React, { useState, useEffect } from 'react'
import Head from 'next/head'
import Image from 'next/image'
import LawsuitPost from '../components/lawsuit/LawsuitPost'
import styles from '../styles/Home.module.css'
import { supabase } from '../utils/supabaseClient'
import StepForm from '../components/form/HomepageStepForm/StepForm'
import Header from '../components/Header'
import LandingPageHome from '../components/LandingPageHome'
import LandingPageLawyer from '../components/LandingPageLawyer'

export default function Home(props) {

  return (
    <div>
      <Header title="TheLegalCanary.com" description="Here to connect you with qualified personal injury lawyers A.S.A.P." keywords="personal injury lawsuit information"/>
      {/* <LandingPageHome lawsuits={props.lawsuits} /> */}
      <LandingPageLawyer lawsuits={props.lawsuits} />
    </div>
  );
}

export const getStaticProps = async () => {
  const { data: lawsuits } = await supabase
    .from('lawsuits')
    .select(`
      id,
      title,
      subtitle,
      products,
      injuries,
      product_image,
      slug
  `)
  .order('published_at', { ascending: false })
  .eq("published", true)


  return {
    props: {
      lawsuits
    }
  }
}
